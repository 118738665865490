import {ApplicationController} from "./application_controller";
import Tabulator from "tabulator-tables";
import {getMetaValue} from "../helpers/index";

export class TransportMainController extends ApplicationController {
  // Fetch
  _buildFetchParamsForJson(method, body) {
    return {
      method: method,
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
      dataType: "application/json",
      credentials: "include",
      body: body
    };
  }


  _buildFetchParamsForJs(method, body) {
    return {
      method: method,
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
      dataType: "text/javascript",
      credentials: "include",
      body: body
    };
  }

  // Table, Tabulator
  //
  _getDefaultTableProps(url) {
    const height = document.getElementById('content').clientHeight;

    let props = {
      virtualDomBuffer: 8000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    return Object.assign(super.tabulatorDefaultProps, props);
  }

  // Sidebar Table props
  _getDefaultSidebarTableProps(url) {
    let props = {
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
    }

    return Object.assign(super.tabulatorDefaultProps, props);
  }

  _defaultFormatRow(row) {
    const data = row.getData();
    if (data.url) row.getElement().dataset.url = data.url;
    if (data.history_url) row.getElement().dataset.history_url = data.history_url;
    if (data.is_selected) {
      row.select();
      row.getElement().classList.add("tabulator-selected");
    }

    if (data.start_today === true) {
      row.getElement().classList.add("order_today");
    }
  }

  async _defaultRowClick(e, row) {
    const content_url = row.getElement().dataset.url;
    const history_url = row.getElement().dataset.history_url;
    const content_target = document.getElementById("content");
    //
    let content_response = await fetch(content_url);
    content_target.innerHTML = await content_response.text();
    await super.updateSidebarStick();
    //
    let row_table = row.getTable();
    row_table.deselectRow();
    row.select();
    super.historyPush(history_url);
  }

  _getSelectCollectionValues(target) {
    if (target.choices) {
      return target.choices.getValue(true)
    } else if (target.getAttribute("data-selected")) {
      return JSON.parse(target.getAttribute("data-selected"));
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.value; });
    }
  }

  _hideOrUpdateModalContent(modal, data) {
    if (data.save_and_new === true || data.save_and_new === "true") {
      modal.setContent(data.form_content)
    } else {
      modal.hide();
    }
  }

  async _createTable(url, table_target, persistenceID, columns, sort_column, sort_dir, filters) {
    let table_props = this._buildTableProps(url, persistenceID, columns, sort_column, sort_dir, filters);

    // create table
    table_target.tabulator = await new Tabulator(table_target, table_props);
    await table_target.tabulator.setLocale(super.appLocale);
  }

  _buildTableProps(url, persistenceID, columns, sort_column, sort_dir, filters) {
    let props = {
      columns: columns,
      persistenceID: persistenceID,
      // sort
      initialSort: [{ column: sort_column, dir: sort_dir }],
      initialFilter: filters
    }
    //
    return Object.assign(this._getDefaultTableProps(url), props);
  }

  async _setFilterToTable(tableTabulator, filters) {
    if (!tableTabulator) return;

    if (filters.length) {
      console.trace('_setFilterToTable method called');
      await tableTabulator.setFilter(filters);
    } else {
      await tableTabulator.clearFilter(true);
    }
  }

  //
  // END Table, Tabulator
  //

  // For fix bug with TypeError: Cannot read properties of undefined (reading 'getElement')
  _filtersChangedForTable(tableTarget) {
    if (!tableTarget) return;

    tableTarget.dataset.filtersChanged = 'true';
  }

  _filtersAppliedForTable(tableTarget) {
    if (!tableTarget) return;

    tableTarget.dataset.filtersChanged = 'false';
  }

  _isFiltersChangedForTable(tableTarget) {
    if (!tableTarget) return false;

    return tableTarget.dataset.filtersChanged === 'true';
  }

  //
  // Notify
  //
  _successNotify(title, message) {
    window.vNotify.success({ title: title, text: message });
  }

  _errorNotify(title, message) {
    window.vNotify.error({ title: title, text: message });
  }

  _warningNotify(title, message) {
    window.vNotify.warning({ title: title, text: message });
  }

  _infoNotify(title, message) {
    window.vNotify.info({ title: title, text: message });
  }

  //
  //
  _deleteTabulatorRowById(tableTabulator, rowId) {
    let row = tableTabulator.getRow(rowId)
    if (!row) return;

    row.delete();
  }

  //
  // History
  //
  _filtersToHistoryUrl(filters) {
    let history_url = window.location.href.split("?")[0];
    let filters_url = this._convertToUrlParams(filters);

    if (filters_url.toString())
      history_url += "?" + filters_url;

    super.historyPush(history_url);
  }

  _convertToUrlParams(filters) {
    let query_filters = {}
    filters.forEach(el => {
      if (el["value"].length)
        query_filters[el["field"]] = el["value"];
    })

    return new URLSearchParams(query_filters);
  }
}