import {OrdersController} from "../orders_controller";
import BSN from "bootstrap.native";

export default class extends OrdersController {
  static targets = [
    // preferred_cars form
    "preferred_car_models_ul",
    "preferred_car_models_count",
    "preferred_car_models_make_id",
    "preferred_car_models_errors",
    "preferred_car_models_errors_text",
    "preferred_car_models_btn",
    "preferred_car_models",
    //  service form
    "order_services_errors",
    "order_services_errors_text",
    "service_start_at",
    "service_id",
    "add_service_btn",
    "services_table",
    "services_table_tbody",
    "services_table_total_value",
    "hidden_services",
    //   extra_services
    "extra_services",
    //   form
    "form_main",
    "order_modal_xl",
    "order_client_modal_xl",
    "choices_ajax_search_clients"
  ]

  connect() {
    if (this.hasOrder_modal_xlTarget) {
      window.order_layout_modal_xl = new BSN.Modal(this.order_modal_xlTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasOrder_client_modal_xlTarget) {
      window.order_client_layout_modal_xl = new BSN.Modal(this.order_client_modal_xlTarget, {
        backdrop: "static",
        keyboard: true
      });

      // reset modal connect, remove leave page from form
      this.order_client_modal_xlTarget.addEventListener('hide.bs.modal', function (event) {
        window.order_client_layout_modal_xl.setContent("");
      }, false);
    }
  }

  async newOrder(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    try {
      await this._openNewOrderModalForm(url);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  showOrderModal(event) {
    event.preventDefault();
    this._showOrderModal();
  }

  async newOrderClient(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    if (window.order_client_layout_modal_xl) {
      this.removeBackdrops();
      await this._openNewClientModalForm(url);
      this._hideOrderModal();
    }
  }


  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_xl, data);

      Turbolinks.visit(data.url);
    }
  }

  onClientSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      this._hideOrderClientModal();
      this._showOrderModal();
      this._setOrderClient(data);
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);
      this.form_mainTarget.innerHTML = data.form_content;
    }
  }

  addSelectedMake(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    if (!btn_target.classList.contains("disabled")) {
      let form_valid = this.validatePreferredCarForm();
      if (form_valid) {
        this.addVehicleMakeRequestToForm();
        }
    }
  }

  removePreferredCarModel(event) {
    event.preventDefault();
    let make_id = event.currentTarget.getAttribute("data-make-id");
    let current_value = this.preferred_car_modelsTarget.value;
    if (!current_value) { return; }
    current_value = JSON.parse(current_value);
    current_value = current_value.filter((item) => {
      return item.make_id !== make_id;
    });
    current_value = JSON.stringify(current_value);
    this.preferred_car_modelsTarget.value = current_value;
    this.buildOrUpdatePreferredCarModelsUl();
  }

  async addSelectedService(event) {
    event.preventDefault();
    const btn_target = event.currentTarget;
    const url = btn_target.getAttribute("data-url");
    if (!btn_target.classList.contains("disabled")) {
      let form_valid = this.validateServiceForm();
      if (form_valid) {
        await this.addServiceToForm(url);
      }
    }
  }

  removeServiceFromTable(event) {
    event.preventDefault();
    const btn_target = event.currentTarget;
    const tr = btn_target.closest("tr");
    const row_id = btn_target.getAttribute("data-row-id");
    tr.remove();

    this.reindexServicesHiddenFields(row_id);
    this.reindexFormTableRows();
    this.calculateServicesTotalValue();
  }

  updateExtraServices(event) {
    event.preventDefault();
    this.calculateServicesTotalValue();
  }

  //PRIVATE

  async addServiceToForm(url) {
    const service_id = this.service_idTarget.choices.getValue(true);
    const service_start_at = this.service_start_atTarget.value;
    const client_id = document.getElementById('transport_order_client_id').choices.getValue(true);
    const data = new FormData();
    data.append("service_id", service_id);
    data.append("start_at", service_start_at);
    data.append("client_id", client_id);
    const params = super._buildFetchParamsForJson('POST', data);
    const serviceData = await this.fetchServiceData(url, params);

    this.addServiceFormToTable(serviceData);
    this.reindexFormTableRows();
    this.calculateServicesTotalValue();
  }

  addServiceFormToTable(serviceData) {
    const tbody_target = this.services_table_tbodyTarget;
    const rows_index = tbody_target.querySelectorAll("tr:not(.hidden)").length + 1;
    const hidden_services = this.hidden_servicesTarget;

    const service_id = serviceData.data.id;
    const service_start_at = serviceData.data.start_at;
    const service_price = serviceData.data.price;
    const service_currency = serviceData.data.currency;
    const service_name = serviceData.data.name;
    const service_uniq_id = new Date().getTime();

    let row_html = `<tr>
      <td class="text-center">
        <span class="index">${rows_index}</span>
      </td>
      <td>${service_start_at}</td>
      <td>${service_name}</td>
      <td class="text-right">${service_price} ${service_currency}</td>
      <td class="text-center">
        <a href="#" class="btn-link text-danger" data-action="click->transport--orders--create#removeServiceFromTable" data-row-id="${service_uniq_id}">
          <i class="fa fa-trash"></i>
        </a>
      </td>
    </tr>`;
    let hidden_field_html = `<input type="hidden" name="services[${service_uniq_id}][service_id]" value="${service_id}">
        <input type="hidden" name="services[${service_uniq_id}][start_at]" value="${service_start_at}">
        <input type="hidden" name="services[${service_uniq_id}][price]" value="${service_price}">
        <input type="hidden" name="services[${service_uniq_id}][currency]" value="${service_currency}">`;

    tbody_target.insertAdjacentHTML("beforeend", row_html);
    hidden_services.insertAdjacentHTML("beforeend", hidden_field_html);
  }

  reindexServicesHiddenFields(row_id) {
    const hidden_services = this.hidden_servicesTarget;
    const hidden_fields = hidden_services.querySelectorAll("input");

    hidden_fields.forEach((field) => {
      if (field.name.includes(`services_attributes][${row_id}]`)) {
        field.remove();
      }
    });
  }

  async fetchServiceData(url, params) {
    return await fetch(url, params).then(function (response) {
      return response.json();
    });
  }

  reindexFormTableRows() {
    const tbody_target = this.services_table_tbodyTarget;
    const rows = tbody_target.querySelectorAll("tr:not(.hidden)");
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      tr.querySelector("span.index").innerHTML = i + 1;
    }
  }

  calculateServicesTotalValue() {
    const hidden_services = this.hidden_servicesTarget;
    const hidden_fields = hidden_services.querySelectorAll("input");
    let total_value = 0;
    hidden_fields.forEach((field) => {
      if (field.name.includes("price")) {
        total_value += parseFloat(field.value.replace(',', '.').replace(/[^\d.-]/g, ''));
      }
    });
    total_value += this.calculateExtraServicesTotalValue();
    total_value = super.setNumericFormat(total_value);
    this.services_table_total_valueTarget.innerHTML = total_value;
  }

  calculateExtraServicesTotalValue() {
    const extra_services = this.extra_servicesTargets;
    let total_value = 0;
    extra_services.forEach((service) => {
      if (service.checked) {
        const price = service.getAttribute("data-price");
        total_value += parseFloat(price.replace(',', '.').replace(/[^\d.-]/g, ''));
      }
    });
    return total_value;
  }

  validateServiceForm() {
    const service_id = this.service_idTarget.choices.getValue(true);
    const start_at = this.service_start_atTarget.value;
    const client_id = document.getElementById('transport_order_client_id').choices.getValue(true);

    let errors = [];
    if (!client_id) {
      errors.push("Выберите клиента");
    }
    if (!service_id) {
      errors.push("Выберите услугу");
    }
    if (!start_at) {
      errors.push("Введите дату начала");
    }
    if (errors.length > 0) {
      this.order_services_errors_textTarget.innerHTML = errors.join("<br>");
      this.order_services_errorsTarget.classList.remove("hidden");
    } else {
      this.order_services_errors_textTarget.innerHTML = "";
      this.order_services_errorsTarget.classList.add("hidden");
    }
    return errors.length === 0;
  }

  async _openNewOrderModalForm(content_url) {
    let content_response = await fetch(content_url);
    let content = await content_response.text();

    if (window.order_layout_modal_xl) {
      window.order_layout_modal_xl.setContent(content);
      window.order_layout_modal_xl.show();
      this.draggable_modal('#order_modal_xl .modal-content');
    }
  }

  removeBackdrops() {
    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove every modal backdrop
    for (let i = 0; i < modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }
  }

  async _openNewClientModalForm(content_url){
    let content_response = await fetch(content_url);
    let content = await content_response.text();

    if (window.order_client_layout_modal_xl) {
      window.order_client_layout_modal_xl.setContent(content);
      window.order_client_layout_modal_xl.show();
      this.draggable_modal('#order_client_modal_xl .modal-content');
    }

  }

  _hideOrderModal() {
    if (window.order_layout_modal_xl) {
      window.order_layout_modal_xl.hide();
    }
  }

  _hideOrderClientModal() {
    if (window.order_client_layout_modal_xl) {
      window.order_client_layout_modal_xl.hide();
    }
  }

  // push to choose and set select created client
  _setOrderClient(data) {
    const clientInput = document.getElementById('transport_order_client_id');


    if (clientInput) {
      clientInput.dataset.currentId = data.row_data['id'];
      const choice = clientInput.choices
      // Set data to choices and set selected it

      // set setChoices
      choice.setChoices([{
        value: data.row_data['id'],
        label: data.row_data['clients__download_name'],
      }]);

      // select data
      choice.setChoiceByValue(data.row_data['id']);
    } else {
      console.error('Element with id "transport_order_client_id" not found');
    }
  }

  get _clientInputChoice() {
    const clientInput = document.getElementById('transport_order_client_id');

    return clientInput.choices;
  }

  _showOrderModal() {
    if (window.order_layout_modal_xl) {
      this._hideOrderClientModal();
      this.removeBackdrops();
      window.order_layout_modal_xl.toggle();
      window.order_layout_modal_xl.update();
    }
  }
}