import moment from "moment/moment";
import {buildExportUrl, exportTableXlsx} from "../../../helpers/tabulator_export_helper";
import {OrdersController} from "../orders_controller";

export default class extends OrdersController {
  // Actions
  exportTable(event) {
    event.stopPropagation();

    this._exportTableXlsx();
  }

  // Private

  // export
  // Export from tabulator
  _exportTableXlsx() {
    let tableTabulator = super._activeTabulator;
    let url = buildExportUrl(tableTabulator);
    let title = "Orders - " + moment().format("YYYY-MM-DD_HH-mm-ss");

    exportTableXlsx(url, tableTabulator, title);
  }
}